import React from "react"
import Header from "../components/header"

import "normalize.css"
import "inter-ui"

import "./privacy-policy.scss"

export default () => (
  <div>
    <Header />
    <div className="privacy-policy">
      <h1 className="privacy-policy__title">Privacy Policy</h1>
      <p className="privacy-policy__text">
        We don't collect or store any personal information. The app does not
        send or receive any data from the internet.
      </p>
    </div>
  </div>
)
